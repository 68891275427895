body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: "Roboto" sans-serif;
}

@font-face {
  font-family: "Arial Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Arial Regular"), url("./fonts/arial/ARIAL.woff") format("woff");
}

@font-face {
  font-family: "Arial Narrow";
  font-style: normal;
  font-weight: normal;
  src: local("Arial Narrow"), url("./fonts/arial/ARIALN.woff") format("woff");
}

@font-face {
  font-family: "Arial Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Arial Italic"), url("./fonts/arial/ARIALI 1.woff") format("woff");
}

@font-face {
  font-family: "Arial Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Arial Italic"), url("./fonts/arial/ARIALI.woff") format("woff");
}

@font-face {
  font-family: "Arial Narrow Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Arial Narrow Italic"),
    url("./fonts/arial/ARIALNI.woff") format("woff");
}

@font-face {
  font-family: "Arial Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Arial Medium"), url("./fonts/arial/ArialMdm.woff") format("woff");
}

@font-face {
  font-family: "Arial Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Arial Medium Italic"),
    url("./fonts/arial/ArialMdmItl.woff") format("woff");
}

@font-face {
  font-family: "Arial Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Arial Bold"), url("./fonts/arial/ARIALBD 1.woff") format("woff");
}

@font-face {
  font-family: "Arial Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Arial Bold"), url("./fonts/arial/ARIALBD.woff") format("woff");
}

@font-face {
  font-family: "Arial Narrow Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Arial Narrow Bold"),
    url("./fonts/arial/ARIALNB.woff") format("woff");
}

@font-face {
  font-family: "Arial Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Arial Bold Italic"),
    url("./fonts/arial/ARIALBI 1.woff") format("woff");
}

@font-face {
  font-family: "Arial Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Arial Bold Italic"),
    url("./fonts/arial/ARIALBI.woff") format("woff");
}

@font-face {
  font-family: "Arial Narrow Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Arial Narrow Bold Italic"),
    url("./fonts/arial/ARIALNBI.woff") format("woff");
}

@font-face {
  font-family: "Arial Black";
  font-style: normal;
  font-weight: normal;
  src: local("Arial Black"), url("./fonts/arial/ARIBLK.woff") format("woff");
}

@font-face {
  font-family: "Arial Black Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Arial Black Italic"),
    url("./fonts/arial/ARIALBLACKITALIC.woff") format("woff");
}

@font-face {
  font-family: "Arial Light Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Arial Light Regular"),
    url("./fonts/arial/ARIALLGT.woff") format("woff");
}

@font-face {
  font-family: "Arial CE Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Arial CE Regular"),
    url("./fonts/arial/ArialCE.woff") format("woff");
}

@font-face {
  font-family: "Arial CE MT Black Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Arial CE MT Black Regular"),
    url("./fonts/arial/ArialCEMTBlack.woff") format("woff");
}

@font-face {
  font-family: "Arial CE Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Arial CE Bold"), url("./fonts/arial/arialceb.woff") format("woff");
}

@font-face {
  font-family: "Arial Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Arial Light Italic"),
    url("./fonts/arial/ARIALLGTITL.woff") format("woff");
}

@font-face {
  font-family: "Arial CE Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Arial CE Italic"),
    url("./fonts/arial/ArialCEItalic.woff") format("woff");
}

@font-face {
  font-family: "Arial CE Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Arial CE Bold Italic"),
    url("./fonts/arial/ArialCEBoldItalic.woff") format("woff");
}

@font-face {
  font-family: "Calibri Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Calibri Bold Italic"),
    url("./fonts/calibri/Calibri Bold Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Calibri Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Calibri Bold"),
    url("./fonts/calibri/Calibri Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Calibri Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Calibri Italic"),
    url("./fonts/calibri/Calibri Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Calibri Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Calibri Light Italic"),
    url("./fonts/calibri/Calibri Light Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Calibri Light";
  font-style: normal;
  font-weight: normal;
  src: local("Calibri Light"),
    url("./fonts/calibri/Calibri Light.ttf") format("truetype");
}

@font-face {
  font-family: "Calibri Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Calibri Regular"),
    url("./fonts/calibri/Calibri Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Lucida Sans Unicode";
  font-style: normal;
  font-weight: normal;
  src: local("Lucida Sans Unicode"),
    url("./fonts/lucida/l_10646.ttf") format("ttf");
}

@font-face {
  font-family: "Tahoma";
  font-style: normal;
  font-weight: normal;
  src: local("Tahoma"),
    url("./fonts/tahoma/tahoma.ttf") format("ttf");
}

@font-face {
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: normal;
  src: local("Times New Roman"),
    url("./fonts/times/times.ttf") format("ttf");
}

@font-face {
  font-family: 'Trebuchet MS';
  font-style: normal;
  font-weight: normal;
  src: local('Trebuchet MS'), url('./fonts/trebuchet/trebuc.woff') format('woff');
}


@font-face {
  font-family: 'Trebuchet MS Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Trebuchet MS Italic'), url('./fonts/trebuchet/Trebuchet-MS-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Verdana';
  font-weight: normal;
  font-style: normal;
  src: local('Verdana'),
    url('./fonts/verdana/Verdana.woff') format('woff');
}

@font-face {
  font-family: 'Helvetica';
  src: local('Helvetica'),
    url('./fonts/helvetica/Helvetica.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Oblique';
  src: local('Helvetica Oblique'), local('Helvetica-Oblique'),
    url('./fonts/helvetica/Helvetica-Oblique.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Bold Oblique';
  src: local('Helvetica Bold Oblique'), local('Helvetica-BoldOblique'),
    url('./fonts/helvetica/Helvetica-BoldOblique.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Light';
  src: local('Helvetica Light'), local('Helvetica-Light'),
    url('./fonts/helvetica/Helvetica-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Bold';
  src: local('Helvetica Bold'), local('Helvetica-Bold'),
    url('./fonts/helvetica/Helvetica-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Light Oblique';
  src: local('Helvetica Light Oblique'), local('Helvetica-LightOblique'),
    url('./fonts/helvetica/Helvetica-LightOblique.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'FrutigerLTStd Black';
  src: local('FrutigerLTStd Black'), local('FrutigerLTStd-Black'),
    url('./fonts/frutiger/FrutigerLTStd-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FrutigerLTStd BlackCn';
  src: local('FrutigerLTStd BlackCn'), local('FrutigerLTStd-BlackCn'),
    url('./fonts/frutiger/FrutigerLTStd-BlackCn.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FrutigerLTStd BlackItalic';
  src: local('FrutigerLTStd BlackItalic'), local('FrutigerLTStd-BlackItalic'),
    url('./fonts/frutiger/FrutigerLTStd-BlackItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FrutigerLTStd Bold';
  src: local('FrutigerLTStd Bold'), local('FrutigerLTStd-Bold'),
    url('./fonts/frutiger/FrutigerLTStd-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FrutigerLTStd BoldCn';
  src: local('FrutigerLTStd BoldCn'), local('FrutigerLTStd-BoldCn'),
    url('./fonts/frutiger/FrutigerLTStd-BoldCn.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FrutigerLTStd BoldItalic';
  src: local('FrutigerLTStd BoldItalic'), local('FrutigerLTStd-BoldItalic'),
    url('./fonts/frutiger/FrutigerLTStd-BoldItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FrutigerLTStd Cn';
  src: local('FrutigerLTStd Cn'), local('FrutigerLTStd-Cn'),
    url('./fonts/frutiger/FrutigerLTStd-Cn.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FrutigerLTStd ExtraBlackCn';
  src: local('FrutigerLTStd ExtraBlackCn'), local('FrutigerLTStd-ExtraBlackCn'),
    url('./fonts/frutiger/FrutigerLTStd-ExtraBlackCn.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FrutigerLTStd Italic';
  src: local('FrutigerLTStd Italic'), local('FrutigerLTStd-Italic'),
    url('./fonts/frutiger/FrutigerLTStd-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FrutigerLTStd Light';
  src: local('FrutigerLTStd Light'), local('FrutigerLTStd-Light'),
    url('./fonts/frutiger/FrutigerLTStd-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FrutigerLTStd LightCn';
  src: local('FrutigerLTStd LightCn'), local('FrutigerLTStd-LightCn'),
    url('./fonts/frutiger/FrutigerLTStd-LightCn.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FrutigerLTStd LightItalic';
  src: local('FrutigerLTStd LightItalic'), local('FrutigerLTStd-LightItalic'),
    url('./fonts/frutiger/FrutigerLTStd-LightItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FrutigerLTStd Roman';
  src: local('FrutigerLTStd Roman'), local('FrutigerLTStd-Roman'),
    url('./fonts/frutiger/FrutigerLTStd-Roman.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FrutigerLTStd UltraBlack';
  src: local('FrutigerLTStd UltraBlack'), local('FrutigerLTStd-UltraBlack'),
    url('./fonts/frutiger/FrutigerLTStd-UltraBlack.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Univers LT Std';
  src: url('./fonts/univers/UniversLTStd-CnObl.eot');
  src: local('Univers LT Std 57 Condensed Oblique'), local('UniversLTStd-CnObl'),
    url('./fonts/univers/UniversLTStd-CnObl.eot?#iefix') format('embedded-opentype'),
    url('./fonts/univers/UniversLTStd-CnObl.woff2') format('woff2'),
    url('./fonts/univers/UniversLTStd-CnObl.woff') format('woff'),
    url('./fonts/univers/UniversLTStd-CnObl.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Univers LT Std';
  src: local('Univers LT Std 93 Extra Black Extended'), local('UniversLTStd-XBlackEx'),
    url('./fonts/univers/UniversLTStd-XBlackEx.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Univers LT Std';
  src: url('./fonts/univers/UniversLTStd-Light.eot');
  src: local('Univers LT Std 45 Light'), local('UniversLTStd-Light'),
    url('./fonts/univers/UniversLTStd-Light.eot?#iefix') format('embedded-opentype'),
    url('./fonts/univers/UniversLTStd-Light.woff2') format('woff2'),
    url('./fonts/univers/UniversLTStd-Light.woff') format('woff'),
    url('./fonts/univers/UniversLTStd-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Univers LT Std';
  src: url('./fonts/univers/UniversLTStd-BoldCn.eot');
  src: local('Univers LT Std 67 Bold Condensed'), local('UniversLTStd-BoldCn'),
    url('./fonts/univers/UniversLTStd-BoldCn.eot?#iefix') format('embedded-opentype'),
    url('./fonts/univers/UniversLTStd-BoldCn.woff2') format('woff2'),
    url('./fonts/univers/UniversLTStd-BoldCn.woff') format('woff'),
    url('./fonts/univers/UniversLTStd-BoldCn.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Univers LT Std';
  src: url('./fonts/univers/UniversLTStd-Black.eot');
  src: local('Univers LT Std 75 Black'), local('UniversLTStd-Black'),
    url('./fonts/univers/UniversLTStd-Black.eot?#iefix') format('embedded-opentype'),
    url('./fonts/univers/UniversLTStd-Black.woff2') format('woff2'),
    url('./fonts/univers/UniversLTStd-Black.woff') format('woff'),
    url('./fonts/univers/UniversLTStd-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Univers LT Std';
  src: url('./fonts/univers/UniversLTStd-LightUltraCn.eot');
  src: local('Univers LT Std 49 Light Ultra Condensed'), local('UniversLTStd-LightUltraCn'),
    url('./fonts/univers/UniversLTStd-LightUltraCn.eot?#iefix') format('embedded-opentype'),
    url('./fonts/univers/UniversLTStd-LightUltraCn.woff2') format('woff2'),
    url('./fonts/univers/UniversLTStd-LightUltraCn.woff') format('woff'),
    url('./fonts/univers/UniversLTStd-LightUltraCn.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Univers LT Std';
  src: url('./fonts/univers/UniversLTStd-LightObl.eot');
  src: local('Univers LT Std 45 Light Oblique'), local('UniversLTStd-LightObl'),
    url('./fonts/univers/UniversLTStd-LightObl.eot?#iefix') format('embedded-opentype'),
    url('./fonts/univers/UniversLTStd-LightObl.woff2') format('woff2'),
    url('./fonts/univers/UniversLTStd-LightObl.woff') format('woff'),
    url('./fonts/univers/UniversLTStd-LightObl.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Univers LT Std';
  src: url('./fonts/univers/UniversLTStd-Ex.eot');
  src: local('Univers LT Std 53 Extended'), local('UniversLTStd-Ex'),
    url('./fonts/univers/UniversLTStd-Ex.eot?#iefix') format('embedded-opentype'),
    url('./fonts/univers/UniversLTStd-Ex.woff2') format('woff2'),
    url('./fonts/univers/UniversLTStd-Ex.woff') format('woff'),
    url('./fonts/univers/UniversLTStd-Ex.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Univers LT Std';
  src: url('./fonts/univers/UniversLTStd-BoldExObl.eot');
  src: local('Univers LT Std 63 Bold Extended Oblique'), local('UniversLTStd-BoldExObl'),
    url('./fonts/univers/UniversLTStd-BoldExObl.eot?#iefix') format('embedded-opentype'),
    url('./fonts/univers/UniversLTStd-BoldExObl.woff2') format('woff2'),
    url('./fonts/univers/UniversLTStd-BoldExObl.woff') format('woff'),
    url('./fonts/univers/UniversLTStd-BoldExObl.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Univers LT Std';
  src: url('./fonts/univers/UniversLTStd-Bold.eot');
  src: local('Univers LT Std 65 Bold'), local('UniversLTStd-Bold'),
    url('./fonts/univers/UniversLTStd-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/univers/UniversLTStd-Bold.woff2') format('woff2'),
    url('./fonts/univers/UniversLTStd-Bold.woff') format('woff'),
    url('./fonts/univers/UniversLTStd-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Univers LT Std';
  src: url('./fonts/univers/UniversLTStd-BlackObl.eot');
  src: local('Univers LT Std 75 Black Oblique'), local('UniversLTStd-BlackObl'),
    url('./fonts/univers/UniversLTStd-BlackObl.eot?#iefix') format('embedded-opentype'),
    url('./fonts/univers/UniversLTStd-BlackObl.woff2') format('woff2'),
    url('./fonts/univers/UniversLTStd-BlackObl.woff') format('woff'),
    url('./fonts/univers/UniversLTStd-BlackObl.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Univers LT Std';
  src: url('./fonts/univers/UniversLTStd-Cn.eot');
  src: local('Univers LT Std 57 Condensed'), local('UniversLTStd-Cn'),
    url('./fonts/univers/UniversLTStd-Cn.eot?#iefix') format('embedded-opentype'),
    url('./fonts/univers/UniversLTStd-Cn.woff2') format('woff2'),
    url('./fonts/univers/UniversLTStd-Cn.woff') format('woff'),
    url('./fonts/univers/UniversLTStd-Cn.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Univers LT Std';
  src: url('./fonts/univers/UniversLTStd-XBlack.eot');
  src: local('Univers LT Std 85 Extra Black'), local('UniversLTStd-XBlack'),
    url('./fonts/univers/UniversLTStd-XBlack.eot?#iefix') format('embedded-opentype'),
    url('./fonts/univers/UniversLTStd-XBlack.woff2') format('woff2'),
    url('./fonts/univers/UniversLTStd-XBlack.woff') format('woff'),
    url('./fonts/univers/UniversLTStd-XBlack.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Univers LT Std';
  src: url('./fonts/univers/UniversLTStd-BoldObl.eot');
  src: local('Univers LT Std 65 Bold Oblique'), local('UniversLTStd-BoldObl'),
    url('./fonts/univers/UniversLTStd-BoldObl.eot?#iefix') format('embedded-opentype'),
    url('./fonts/univers/UniversLTStd-BoldObl.woff2') format('woff2'),
    url('./fonts/univers/UniversLTStd-BoldObl.woff') format('woff'),
    url('./fonts/univers/UniversLTStd-BoldObl.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Univers LT Std';
  src: url('./fonts/univers/UniversLTStd-Obl.eot');
  src: local('Univers LT Std 55 Oblique'), local('UniversLTStd-Obl'),
    url('./fonts/univers/UniversLTStd-Obl.eot?#iefix') format('embedded-opentype'),
    url('./fonts/univers/UniversLTStd-Obl.woff2') format('woff2'),
    url('./fonts/univers/UniversLTStd-Obl.woff') format('woff'),
    url('./fonts/univers/UniversLTStd-Obl.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Univers LT Std';
  src: url('./fonts/univers/UniversLTStd-BlackExObl.eot');
  src: local('Univers LT Std 73 Black Extended Oblique'), local('UniversLTStd-BlackExObl'),
    url('./fonts/univers/UniversLTStd-BlackExObl.eot?#iefix') format('embedded-opentype'),
    url('./fonts/univers/UniversLTStd-BlackExObl.woff2') format('woff2'),
    url('./fonts/univers/UniversLTStd-BlackExObl.woff') format('woff'),
    url('./fonts/univers/UniversLTStd-BlackExObl.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Univers LT Std';
  src: url('./fonts/univers/UniversLTStd-ExObl.eot');
  src: local('Univers LT Std 53 Extended Oblique'), local('UniversLTStd-ExObl'),
    url('./fonts/univers/UniversLTStd-ExObl.eot?#iefix') format('embedded-opentype'),
    url('./fonts/univers/UniversLTStd-ExObl.woff2') format('woff2'),
    url('./fonts/univers/UniversLTStd-ExObl.woff') format('woff'),
    url('./fonts/univers/UniversLTStd-ExObl.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Univers LT Std';
  src: url('./fonts/univers/UniversLTStd-LightCn.eot');
  src: local('Univers LT Std 47 Light Condensed'), local('UniversLTStd-LightCn'),
    url('./fonts/univers/UniversLTStd-LightCn.eot?#iefix') format('embedded-opentype'),
    url('./fonts/univers/UniversLTStd-LightCn.woff2') format('woff2'),
    url('./fonts/univers/UniversLTStd-LightCn.woff') format('woff'),
    url('./fonts/univers/UniversLTStd-LightCn.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Univers LT Std';
  src: url('./fonts/univers/UniversLTStd-XBlackExObl.eot');
  src: local('Univers LT Std 93 Extra Black Extended Oblique'), local('UniversLTStd-XBlackExObl'),
    url('./fonts/univers/UniversLTStd-XBlackExObl.eot?#iefix') format('embedded-opentype'),
    url('./fonts/univers/UniversLTStd-XBlackExObl.woff2') format('woff2'),
    url('./fonts/univers/UniversLTStd-XBlackExObl.woff') format('woff'),
    url('./fonts/univers/UniversLTStd-XBlackExObl.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Univers LT Std';
  src: url('./fonts/univers/UniversLTStd-ThinUltraCn.eot');
  src: local('Univers LT Std 39 Thin Ultra Condensed'), local('UniversLTStd-ThinUltraCn'),
    url('./fonts/univers/UniversLTStd-ThinUltraCn.eot?#iefix') format('embedded-opentype'),
    url('./fonts/univers/UniversLTStd-ThinUltraCn.woff2') format('woff2'),
    url('./fonts/univers/UniversLTStd-ThinUltraCn.woff') format('woff'),
    url('./fonts/univers/UniversLTStd-ThinUltraCn.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Univers LT Std';
  src: url('./fonts/univers/UniversLTStd.eot');
  src: local('Univers LT Std 55 Roman'), local('UniversLTStd'),
    url('./fonts/univers/UniversLTStd.eot?#iefix') format('embedded-opentype'),
    url('./fonts/univers/UniversLTStd.woff2') format('woff2'),
    url('./fonts/univers/UniversLTStd.woff') format('woff'),
    url('./fonts/univers/UniversLTStd.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Univers LT Std';
  src: url('./fonts/univers/UniversLTStd-LightCnObl.eot');
  src: local('Univers LT Std 47 Light Condensed Oblique'), local('UniversLTStd-LightCnObl'),
    url('./fonts/univers/UniversLTStd-LightCnObl.eot?#iefix') format('embedded-opentype'),
    url('./fonts/univers/UniversLTStd-LightCnObl.woff2') format('woff2'),
    url('./fonts/univers/UniversLTStd-LightCnObl.woff') format('woff'),
    url('./fonts/univers/UniversLTStd-LightCnObl.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Univers LT Std';
  src: url('./fonts/univers/UniversLTStd-BlackEx.eot');
  src: local('Univers LT Std 73 Black Extended'), local('UniversLTStd-BlackEx'),
    url('./fonts/univers/UniversLTStd-BlackEx.eot?#iefix') format('embedded-opentype'),
    url('./fonts/univers/UniversLTStd-BlackEx.woff2') format('woff2'),
    url('./fonts/univers/UniversLTStd-BlackEx.woff') format('woff'),
    url('./fonts/univers/UniversLTStd-BlackEx.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Univers LT Std 59 UltraCn';
  src: url('./fonts/univers/UniversLTStd-UltraCn.eot');
  src: local('Univers LT Std 59 Ultra Condensed'), local('UniversLTStd-UltraCn'),
    url('./fonts/univers/UniversLTStd-UltraCn.eot?#iefix') format('embedded-opentype'),
    url('./fonts/univers/UniversLTStd-UltraCn.woff2') format('woff2'),
    url('./fonts/univers/UniversLTStd-UltraCn.woff') format('woff'),
    url('./fonts/univers/UniversLTStd-UltraCn.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Univers LT Std';
  src: url('./fonts/univers/UniversLTStd-XBlackObl.eot');
  src: local('Univers LT Std 85 Extra Black Oblique'), local('UniversLTStd-XBlackObl'),
    url('./fonts/univers/UniversLTStd-XBlackObl.eot?#iefix') format('embedded-opentype'),
    url('./fonts/univers/UniversLTStd-XBlackObl.woff2') format('woff2'),
    url('./fonts/univers/UniversLTStd-XBlackObl.woff') format('woff'),
    url('./fonts/univers/UniversLTStd-XBlackObl.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Univers LT Std';
  src: url('./fonts/univers/UniversLTStd-BoldEx.eot');
  src: local('Univers LT Std 63 Bold Extended'), local('UniversLTStd-BoldEx'),
    url('./fonts/univers/UniversLTStd-BoldEx.eot?#iefix') format('embedded-opentype'),
    url('./fonts/univers/UniversLTStd-BoldEx.woff2') format('woff2'),
    url('./fonts/univers/UniversLTStd-BoldEx.woff') format('woff'),
    url('./fonts/univers/UniversLTStd-BoldEx.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Univers LT Std';
  src: url('./fonts/univers/UniversLTStd-BoldCnObl.eot');
  src: local('Univers LT Std 67 Bold Condensed Oblique'), local('UniversLTStd-BoldCnObl'),
    url('./fonts/univers/UniversLTStd-BoldCnObl.eot?#iefix') format('embedded-opentype'),
    url('./fonts/univers/UniversLTStd-BoldCnObl.woff2') format('woff2'),
    url('./fonts/univers/UniversLTStd-BoldCnObl.woff') format('woff'),
    url('./fonts/univers/UniversLTStd-BoldCnObl.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Futura LT';
  src: url('./fonts/futura/FuturaLT-CondensedOblique.eot');
  src: local('Futura LT Condensed Medium Oblique'), local('FuturaLT-CondensedOblique'),
    url('./fonts/futura/FuturaLT-CondensedOblique.eot?#iefix') format('embedded-opentype'),
    url('./fonts/futura/FuturaLT-CondensedOblique.woff2') format('woff2'),
    url('./fonts/futura/FuturaLT-CondensedOblique.woff') format('woff'),
    url('./fonts/futura/FuturaLT-CondensedOblique.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Futura LT';
  src: url('./fonts/futura/FuturaLT-Light.eot');
  src: local('Futura LT Light'), local('FuturaLT-Light'),
    url('./fonts/futura/FuturaLT-Light.eot?#iefix') format('embedded-opentype'),
    url('./fonts/futura/FuturaLT-Light.woff2') format('woff2'),
    url('./fonts/futura/FuturaLT-Light.woff') format('woff'),
    url('./fonts/futura/FuturaLT-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Futura LT';
  src: url('./fonts/futura/FuturaLT-ExtraBold.eot');
  src: local('Futura LT Extra Bold'), local('FuturaLT-ExtraBold'),
    url('./fonts/futura/FuturaLT-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/futura/FuturaLT-ExtraBold.woff2') format('woff2'),
    url('./fonts/futura/FuturaLT-ExtraBold.woff') format('woff'),
    url('./fonts/futura/FuturaLT-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Futura LT';
  src: url('./fonts/futura/FuturaLT-ExtraBoldOblique.eot');
  src: local('Futura LT Extra Bold Oblique'), local('FuturaLT-ExtraBoldOblique'),
    url('./fonts/futura/FuturaLT-ExtraBoldOblique.eot?#iefix') format('embedded-opentype'),
    url('./fonts/futura/FuturaLT-ExtraBoldOblique.woff2') format('woff2'),
    url('./fonts/futura/FuturaLT-ExtraBoldOblique.woff') format('woff'),
    url('./fonts/futura/FuturaLT-ExtraBoldOblique.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Futura LT';
  src: url('./fonts/futura/FuturaLT-LightOblique.eot');
  src: local('Futura LT Light Oblique'), local('FuturaLT-LightOblique'),
    url('./fonts/futura/FuturaLT-LightOblique.eot?#iefix') format('embedded-opentype'),
    url('./fonts/futura/FuturaLT-LightOblique.woff2') format('woff2'),
    url('./fonts/futura/FuturaLT-LightOblique.woff') format('woff'),
    url('./fonts/futura/FuturaLT-LightOblique.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Futura LT';
  src: url('./fonts/futura/FuturaLT-Condensed.eot');
  src: local('Futura LT Condensed Medium'), local('FuturaLT-Condensed'),
    url('./fonts/futura/FuturaLT-Condensed.eot?#iefix') format('embedded-opentype'),
    url('./fonts/futura/FuturaLT-Condensed.woff2') format('woff2'),
    url('./fonts/futura/FuturaLT-Condensed.woff') format('woff'),
    url('./fonts/futura/FuturaLT-Condensed.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Futura LT';
  src: url('./fonts/futura/FuturaLT-Book.eot');
  src: local('Futura LT Book'), local('FuturaLT-Book'),
    url('./fonts/futura/FuturaLT-Book.eot?#iefix') format('embedded-opentype'),
    url('./fonts/futura/FuturaLT-Book.woff2') format('woff2'),
    url('./fonts/futura/FuturaLT-Book.woff') format('woff'),
    url('./fonts/futura/FuturaLT-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Futura LT';
  src: url('./fonts/futura/FuturaLT-Oblique.eot');
  src: local('Futura LT Medium Oblique'), local('FuturaLT-Oblique'),
    url('./fonts/futura/FuturaLT-Oblique.eot?#iefix') format('embedded-opentype'),
    url('./fonts/futura/FuturaLT-Oblique.woff2') format('woff2'),
    url('./fonts/futura/FuturaLT-Oblique.woff') format('woff'),
    url('./fonts/futura/FuturaLT-Oblique.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Futura LT';
  src: url('./fonts/futura/FuturaLT-CondensedExtraBold.eot');
  src: local('Futura LT Condensed Extra Bold'), local('FuturaLT-CondensedExtraBold'),
    url('./fonts/futura/FuturaLT-CondensedExtraBold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/futura/FuturaLT-CondensedExtraBold.woff2') format('woff2'),
    url('./fonts/futura/FuturaLT-CondensedExtraBold.woff') format('woff'),
    url('./fonts/futura/FuturaLT-CondensedExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Futura LT';
  src: url('./fonts/futura/FuturaLT.eot');
  src: local('Futura LT Medium'), local('FuturaLT'),
    url('./fonts/futura/FuturaLT.eot?#iefix') format('embedded-opentype'),
    url('./fonts/futura/FuturaLT.woff2') format('woff2'),
    url('./fonts/futura/FuturaLT.woff') format('woff'),
    url('./fonts/futura/FuturaLT.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Futura LT';
  src: url('./fonts/futura/FuturaLT-CondensedLightObl.eot');
  src: local('Futura LT Condensed Light Oblique'), local('FuturaLT-CondensedLightObl'),
    url('./fonts/futura/FuturaLT-CondensedLightObl.eot?#iefix') format('embedded-opentype'),
    url('./fonts/futura/FuturaLT-CondensedLightObl.woff2') format('woff2'),
    url('./fonts/futura/FuturaLT-CondensedLightObl.woff') format('woff'),
    url('./fonts/futura/FuturaLT-CondensedLightObl.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Futura LT';
  src: url('./fonts/futura/FuturaLT-BookOblique.eot');
  src: local('Futura LT Book Oblique'), local('FuturaLT-BookOblique'),
    url('./fonts/futura/FuturaLT-BookOblique.eot?#iefix') format('embedded-opentype'),
    url('./fonts/futura/FuturaLT-BookOblique.woff2') format('woff2'),
    url('./fonts/futura/FuturaLT-BookOblique.woff') format('woff'),
    url('./fonts/futura/FuturaLT-BookOblique.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Futura LT';
  src: url('./fonts/futura/FuturaLT-CondExtraBoldObl.eot');
  src: local('Futura LT Condensed Extra Bold Oblique'), local('FuturaLT-CondExtraBoldObl'),
    url('./fonts/futura/FuturaLT-CondExtraBoldObl.eot?#iefix') format('embedded-opentype'),
    url('./fonts/futura/FuturaLT-CondExtraBoldObl.woff2') format('woff2'),
    url('./fonts/futura/FuturaLT-CondExtraBoldObl.woff') format('woff'),
    url('./fonts/futura/FuturaLT-CondExtraBoldObl.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Futura LT';
  src: url('./fonts/futura/FuturaLT-HeavyOblique.eot');
  src: local('Futura LT Heavy Oblique'), local('FuturaLT-HeavyOblique'),
    url('./fonts/futura/FuturaLT-HeavyOblique.eot?#iefix') format('embedded-opentype'),
    url('./fonts/futura/FuturaLT-HeavyOblique.woff2') format('woff2'),
    url('./fonts/futura/FuturaLT-HeavyOblique.woff') format('woff'),
    url('./fonts/futura/FuturaLT-HeavyOblique.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Futura LT';
  src: url('./fonts/futura/FuturaLT-BoldOblique.eot');
  src: local('Futura LT Bold Oblique'), local('FuturaLT-BoldOblique'),
    url('./fonts/futura/FuturaLT-BoldOblique.eot?#iefix') format('embedded-opentype'),
    url('./fonts/futura/FuturaLT-BoldOblique.woff2') format('woff2'),
    url('./fonts/futura/FuturaLT-BoldOblique.woff') format('woff'),
    url('./fonts/futura/FuturaLT-BoldOblique.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Futura LT';
  src: url('./fonts/futura/FuturaLT-CondensedBoldOblique.eot');
  src: local('Futura LT Condensed Bold Oblique'), local('FuturaLT-CondensedBoldOblique'),
    url('./fonts/futura/FuturaLT-CondensedBoldOblique.eot?#iefix') format('embedded-opentype'),
    url('./fonts/futura/FuturaLT-CondensedBoldOblique.woff2') format('woff2'),
    url('./fonts/futura/FuturaLT-CondensedBoldOblique.woff') format('woff'),
    url('./fonts/futura/FuturaLT-CondensedBoldOblique.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Futura LT';
  src: url('./fonts/futura/FuturaLT-CondensedLight.eot');
  src: local('Futura LT Condensed Light'), local('FuturaLT-CondensedLight'),
    url('./fonts/futura/FuturaLT-CondensedLight.eot?#iefix') format('embedded-opentype'),
    url('./fonts/futura/FuturaLT-CondensedLight.woff2') format('woff2'),
    url('./fonts/futura/FuturaLT-CondensedLight.woff') format('woff'),
    url('./fonts/futura/FuturaLT-CondensedLight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Futura LT';
  src: url('./fonts/futura/FuturaLT-Bold.eot');
  src: local('Futura LT Bold'), local('FuturaLT-Bold'),
    url('./fonts/futura/FuturaLT-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/futura/FuturaLT-Bold.woff2') format('woff2'),
    url('./fonts/futura/FuturaLT-Bold.woff') format('woff'),
    url('./fonts/futura/FuturaLT-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Futura LT';
  src: url('./fonts/futura/FuturaLT-Heavy.eot');
  src: local('Futura LT Heavy'), local('FuturaLT-Heavy'),
    url('./fonts/futura/FuturaLT-Heavy.eot?#iefix') format('embedded-opentype'),
    url('./fonts/futura/FuturaLT-Heavy.woff2') format('woff2'),
    url('./fonts/futura/FuturaLT-Heavy.woff') format('woff'),
    url('./fonts/futura/FuturaLT-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Futura LT';
  src: url('./fonts/futura/FuturaLT-CondensedBold.eot');
  src: local('Futura LT Condensed Bold'), local('FuturaLT-CondensedBold'),
    url('./fonts/futura/FuturaLT-CondensedBold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/futura/FuturaLT-CondensedBold.woff2') format('woff2'),
    url('./fonts/futura/FuturaLT-CondensedBold.woff') format('woff'),
    url('./fonts/futura/FuturaLT-CondensedBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./fonts/helvetica-neue/HelveticaNeueBoldCondensed.eot');
  src: local('Helvetica Neue Condensed Bold'), local('HelveticaNeueBoldCondensed'),
    url('./fonts/helvetica-neue/HelveticaNeueBoldCondensed.eot?#iefix') format('embedded-opentype'),
    url('./fonts/helvetica-neue/HelveticaNeueBoldCondensed.woff2') format('woff2'),
    url('./fonts/helvetica-neue/HelveticaNeueBoldCondensed.woff') format('woff'),
    url('./fonts/helvetica-neue/HelveticaNeueBoldCondensed.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./fonts/helvetica-neue/HelveticaNeueBlackCondensed.eot');
  src: local('Helvetica Neue Condensed Black'), local('HelveticaNeueBlackCondensed'),
    url('./fonts/helvetica-neue/HelveticaNeueBlackCondensed.eot?#iefix') format('embedded-opentype'),
    url('./fonts/helvetica-neue/HelveticaNeueBlackCondensed.woff2') format('woff2'),
    url('./fonts/helvetica-neue/HelveticaNeueBlackCondensed.woff') format('woff'),
    url('./fonts/helvetica-neue/HelveticaNeueBlackCondensed.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('./fonts/helvetica-neue/HelveticaNeueCyr-Bold.eot');
  src: local('HelveticaNeueCyr-Bold'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Bold.woff2') format('woff2'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Bold.woff') format('woff'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('./fonts/helvetica-neue/HelveticaNeueCyr-Light.eot');
  src: local('HelveticaNeueCyr-Light'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Light.eot?#iefix') format('embedded-opentype'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Light.woff2') format('woff2'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Light.woff') format('woff'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('./fonts/helvetica-neue/HelveticaNeueCyr-Medium.eot');
  src: local('HelveticaNeueCyr-Medium'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Medium.eot?#iefix') format('embedded-opentype'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Medium.woff2') format('woff2'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Medium.woff') format('woff'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('./fonts/helvetica-neue/HelveticaNeueCyr-MediumItalic.eot');
  src: local('HelveticaNeueCyr-MediumItalic'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-MediumItalic.woff2') format('woff2'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-MediumItalic.woff') format('woff'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('./fonts/helvetica-neue/HelveticaNeueCyr-LightItalic.eot');
  src: local('HelveticaNeueCyr-LightItalic'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-LightItalic.woff2') format('woff2'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-LightItalic.woff') format('woff'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('./fonts/helvetica-neue/HelveticaNeueCyr-HeavyItalic.eot');
  src: local('HelveticaNeueCyr-HeavyItalic'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-HeavyItalic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-HeavyItalic.woff2') format('woff2'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-HeavyItalic.woff') format('woff'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-HeavyItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('./fonts/helvetica-neue/HelveticaNeueCyr-Black.eot');
  src: local('HelveticaNeueCyr-Black'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Black.eot?#iefix') format('embedded-opentype'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Black.woff2') format('woff2'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Black.woff') format('woff'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('./fonts/helvetica-neue/HelveticaNeueCyr-Heavy.eot');
  src: local('HelveticaNeueCyr-Heavy'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Heavy.eot?#iefix') format('embedded-opentype'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Heavy.woff2') format('woff2'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Heavy.woff') format('woff'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('./fonts/helvetica-neue/HelveticaNeueCyr-Italic.eot');
  src: local('HelveticaNeueCyr-Italic'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Italic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Italic.woff2') format('woff2'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Italic.woff') format('woff'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Italic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('./fonts/helvetica-neue/HelveticaNeueCyr-Roman.eot');
  src: local('HelveticaNeueCyr-Roman'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Roman.eot?#iefix') format('embedded-opentype'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Roman.woff2') format('woff2'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Roman.woff') format('woff'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Roman.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('./fonts/helvetica-neue/HelveticaNeueCyr-BlackItalic.eot');
  src: local('HelveticaNeueCyr-BlackItalic'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-BlackItalic.woff2') format('woff2'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-BlackItalic.woff') format('woff'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('./fonts/helvetica-neue/HelveticaNeueCyr-UltraLightItalic.eot');
  src: local('HelveticaNeueCyr-UltraLightItalic'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-UltraLightItalic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-UltraLightItalic.woff2') format('woff2'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-UltraLightItalic.woff') format('woff'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-UltraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('./fonts/helvetica-neue/HelveticaNeueCyr-BoldItalic.eot');
  src: local('HelveticaNeueCyr-BoldItalic'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-BoldItalic.woff2') format('woff2'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-BoldItalic.woff') format('woff'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('./fonts/helvetica-neue/HelveticaNeueCyr-ThinItalic.eot');
  src: local('HelveticaNeueCyr-ThinItalic'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-ThinItalic.woff2') format('woff2'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-ThinItalic.woff') format('woff'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('./fonts/helvetica-neue/HelveticaNeueCyr-UltraLight.eot');
  src: local('HelveticaNeueCyr-UltraLight'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-UltraLight.eot?#iefix') format('embedded-opentype'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-UltraLight.woff2') format('woff2'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-UltraLight.woff') format('woff'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-UltraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('./fonts/helvetica-neue/HelveticaNeueCyr-Thin.eot');
  src: local('HelveticaNeueCyr-Thin'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Thin.eot?#iefix') format('embedded-opentype'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Thin.woff2') format('woff2'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Thin.woff') format('woff'),
    url('./fonts/helvetica-neue/HelveticaNeueCyr-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/gotham/Gotham-LightItalic.eot');
  src: local('Gotham Light Italic'), local('Gotham-LightItalic'),
    url('./fonts/gotham/Gotham-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/gotham/Gotham-LightItalic.woff2') format('woff2'),
    url('./fonts/gotham/Gotham-LightItalic.woff') format('woff'),
    url('./fonts/gotham/Gotham-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/gotham/Gotham-BoldItalic.eot');
  src: local('Gotham Bold Italic'), local('Gotham-BoldItalic'),
    url('./fonts/gotham/Gotham-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/gotham/Gotham-BoldItalic.woff2') format('woff2'),
    url('./fonts/gotham/Gotham-BoldItalic.woff') format('woff'),
    url('./fonts/gotham/Gotham-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/gotham/Gotham-XLight.eot');
  src: local('Gotham ExtraLight'), local('Gotham-XLight'),
    url('./fonts/gotham/Gotham-XLight.eot?#iefix') format('embedded-opentype'),
    url('./fonts/gotham/Gotham-XLight.woff2') format('woff2'),
    url('./fonts/gotham/Gotham-XLight.woff') format('woff'),
    url('./fonts/gotham/Gotham-XLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/gotham/Gotham-Thin.eot');
  src: local('Gotham Thin'), local('Gotham-Thin'),
    url('./fonts/gotham/Gotham-Thin.eot?#iefix') format('embedded-opentype'),
    url('./fonts/gotham/Gotham-Thin.woff2') format('woff2'),
    url('./fonts/gotham/Gotham-Thin.woff') format('woff'),
    url('./fonts/gotham/Gotham-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/gotham/Gotham-Book.eot');
  src: local('Gotham Book'), local('Gotham-Book'),
    url('./fonts/gotham/Gotham-Book.eot?#iefix') format('embedded-opentype'),
    url('./fonts/gotham/Gotham-Book.woff2') format('woff2'),
    url('./fonts/gotham/Gotham-Book.woff') format('woff'),
    url('./fonts/gotham/Gotham-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/gotham/Gotham-ThinItalic.eot');
  src: local('Gotham Thin Italic'), local('Gotham-ThinItalic'),
    url('./fonts/gotham/Gotham-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/gotham/Gotham-ThinItalic.woff2') format('woff2'),
    url('./fonts/gotham/Gotham-ThinItalic.woff') format('woff'),
    url('./fonts/gotham/Gotham-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/gotham/Gotham-MediumItalic.eot');
  src: local('Gotham Medium Italic'), local('Gotham-MediumItalic'),
    url('./fonts/gotham/Gotham-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/gotham/Gotham-MediumItalic.woff2') format('woff2'),
    url('./fonts/gotham/Gotham-MediumItalic.woff') format('woff'),
    url('./fonts/gotham/Gotham-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham Ultra';
  src: url('./fonts/gotham/Gotham-UltraItalic.eot');
  src: local('Gotham Ultra Italic'), local('Gotham-UltraItalic'),
    url('./fonts/gotham/Gotham-UltraItalic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/gotham/Gotham-UltraItalic.woff2') format('woff2'),
    url('./fonts/gotham/Gotham-UltraItalic.woff') format('woff'),
    url('./fonts/gotham/Gotham-UltraItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/gotham/Gotham-BookItalic.eot');
  src: local('Gotham Book Italic'), local('Gotham-BookItalic'),
    url('./fonts/gotham/Gotham-BookItalic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/gotham/Gotham-BookItalic.woff2') format('woff2'),
    url('./fonts/gotham/Gotham-BookItalic.woff') format('woff'),
    url('./fonts/gotham/Gotham-BookItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/gotham/Gotham-BlackItalic.eot');
  src: local('Gotham Black Italic'), local('Gotham-BlackItalic'),
    url('./fonts/gotham/Gotham-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/gotham/Gotham-BlackItalic.woff2') format('woff2'),
    url('./fonts/gotham/Gotham-BlackItalic.woff') format('woff'),
    url('./fonts/gotham/Gotham-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/gotham/Gotham-Light.eot');
  src: local('Gotham Light'), local('Gotham-Light'),
    url('./fonts/gotham/Gotham-Light.eot?#iefix') format('embedded-opentype'),
    url('./fonts/gotham/Gotham-Light.woff2') format('woff2'),
    url('./fonts/gotham/Gotham-Light.woff') format('woff'),
    url('./fonts/gotham/Gotham-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/gotham/Gotham-Bold.eot');
  src: local('Gotham Bold'), local('Gotham-Bold'),
    url('./fonts/gotham/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/gotham/Gotham-Bold.woff2') format('woff2'),
    url('./fonts/gotham/Gotham-Bold.woff') format('woff'),
    url('./fonts/gotham/Gotham-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/gotham/Gotham-Medium.eot');
  src: local('Gotham Medium'), local('Gotham-Medium'),
    url('./fonts/gotham/Gotham-Medium.eot?#iefix') format('embedded-opentype'),
    url('./fonts/gotham/Gotham-Medium.woff2') format('woff2'),
    url('./fonts/gotham/Gotham-Medium.woff') format('woff'),
    url('./fonts/gotham/Gotham-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Ultra';
  src: url('./fonts/gotham/Gotham-Ultra.eot');
  src: local('Gotham Ultra'), local('Gotham-Ultra'),
    url('./fonts/gotham/Gotham-Ultra.eot?#iefix') format('embedded-opentype'),
    url('./fonts/gotham/Gotham-Ultra.woff2') format('woff2'),
    url('./fonts/gotham/Gotham-Ultra.woff') format('woff'),
    url('./fonts/gotham/Gotham-Ultra.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/gotham/Gotham-XLightItalic.eot');
  src: local('Gotham ExtraLight Italic'), local('Gotham-XLightItalic'),
    url('./fonts/gotham/Gotham-XLightItalic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/gotham/Gotham-XLightItalic.woff2') format('woff2'),
    url('./fonts/gotham/Gotham-XLightItalic.woff') format('woff'),
    url('./fonts/gotham/Gotham-XLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/gotham/Gotham-Black.eot');
  src: local('Gotham Black'), local('Gotham-Black'),
    url('./fonts/gotham/Gotham-Black.eot?#iefix') format('embedded-opentype'),
    url('./fonts/gotham/Gotham-Black.woff2') format('woff2'),
    url('./fonts/gotham/Gotham-Black.woff') format('woff'),
    url('./fonts/gotham/Gotham-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Bauer";
  font-style: normal;
  font-weight: normal;
  src: local("Bauer"),
    url("./fonts/bauer/bauer.ttf") format("ttf");
}

@font-face {
  font-family: "Rockwell";
  font-style: normal;
  font-weight: normal;
  src: local("Rockwell"),
    url("./fonts/rockwell/rockb.ttf") format("ttf");
}

@font-face {
  font-family: 'Semplicita Pro';
  src: url('./fonts/semplicita/SemplicitaPro-Light.eot');
  src: local('Semplicita Pro Light'), local('SemplicitaPro-Light'),
    url('./fonts/semplicita/SemplicitaPro-Light.eot?#iefix') format('embedded-opentype'),
    url('./fonts/semplicita/SemplicitaPro-Light.woff2') format('woff2'),
    url('./fonts/semplicita/SemplicitaPro-Light.woff') format('woff'),
    url('./fonts/semplicita/SemplicitaPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Semplicita Pro';
  src: url('./fonts/semplicita/SemplicitaPro-Bold.eot');
  src: local('Semplicita Pro Bold'), local('SemplicitaPro-Bold'),
    url('./fonts/semplicita/SemplicitaPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/semplicita/SemplicitaPro-Bold.woff2') format('woff2'),
    url('./fonts/semplicita/SemplicitaPro-Bold.woff') format('woff'),
    url('./fonts/semplicita/SemplicitaPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Semplicita Pro';
  src: url('./fonts/semplicita/SemplicitaPro-BoldItalic.eot');
  src: local('Semplicita Pro Bold Italic'), local('SemplicitaPro-BoldItalic'),
    url('./fonts/semplicita/SemplicitaPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/semplicita/SemplicitaPro-BoldItalic.woff2') format('woff2'),
    url('./fonts/semplicita/SemplicitaPro-BoldItalic.woff') format('woff'),
    url('./fonts/semplicita/SemplicitaPro-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Semplicita Pro';
  src: url('./fonts/semplicita/SemplicitaPro-SemiBoldItalic.eot');
  src: local('Semplicita Pro SemiBold Italic'), local('SemplicitaPro-SemiBoldItalic'),
    url('./fonts/semplicita/SemplicitaPro-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/semplicita/SemplicitaPro-SemiBoldItalic.woff2') format('woff2'),
    url('./fonts/semplicita/SemplicitaPro-SemiBoldItalic.woff') format('woff'),
    url('./fonts/semplicita/SemplicitaPro-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Semplicita Pro';
  src: url('./fonts/semplicita/SemplicitaPro-MediumItalic.eot');
  src: local('Semplicita Pro Medium Italic'), local('SemplicitaPro-MediumItalic'),
    url('./fonts/semplicita/SemplicitaPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/semplicita/SemplicitaPro-MediumItalic.woff2') format('woff2'),
    url('./fonts/semplicita/SemplicitaPro-MediumItalic.woff') format('woff'),
    url('./fonts/semplicita/SemplicitaPro-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Semplicita Pro';
  src: url('./fonts/semplicita/SemplicitaPro-LightItalic.eot');
  src: local('Semplicita Pro Light Italic'), local('SemplicitaPro-LightItalic'),
    url('./fonts/semplicita/SemplicitaPro-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/semplicita/SemplicitaPro-LightItalic.woff2') format('woff2'),
    url('./fonts/semplicita/SemplicitaPro-LightItalic.woff') format('woff'),
    url('./fonts/semplicita/SemplicitaPro-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Semplicita Pro';
  src: url('./fonts/semplicita/SemplicitaPro-SemiBold.eot');
  src: local('Semplicita Pro SemiBold'), local('SemplicitaPro-SemiBold'),
    url('./fonts/semplicita/SemplicitaPro-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/semplicita/SemplicitaPro-SemiBold.woff2') format('woff2'),
    url('./fonts/semplicita/SemplicitaPro-SemiBold.woff') format('woff'),
    url('./fonts/semplicita/SemplicitaPro-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Semplicita Pro';
  src: url('./fonts/semplicita/SemplicitaPro-Regular.eot');
  src: local('Semplicita Pro Regular'), local('SemplicitaPro-Regular'),
    url('./fonts/semplicita/SemplicitaPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/semplicita/SemplicitaPro-Regular.woff2') format('woff2'),
    url('./fonts/semplicita/SemplicitaPro-Regular.woff') format('woff'),
    url('./fonts/semplicita/SemplicitaPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Semplicita Pro';
  src: url('./fonts/semplicita/SemplicitaPro-Italic.eot');
  src: local('Semplicita Pro Italic'), local('SemplicitaPro-Italic'),
    url('./fonts/semplicita/SemplicitaPro-Italic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/semplicita/SemplicitaPro-Italic.woff2') format('woff2'),
    url('./fonts/semplicita/SemplicitaPro-Italic.woff') format('woff'),
    url('./fonts/semplicita/SemplicitaPro-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Semplicita Pro';
  src: url('./fonts/semplicita/SemplicitaPro-Medium.eot');
  src: local('Semplicita Pro Medium'), local('SemplicitaPro-Medium'),
    url('./fonts/semplicita/SemplicitaPro-Medium.eot?#iefix') format('embedded-opentype'),
    url('./fonts/semplicita/SemplicitaPro-Medium.woff2') format('woff2'),
    url('./fonts/semplicita/SemplicitaPro-Medium.woff') format('woff'),
    url('./fonts/semplicita/SemplicitaPro-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "PassionSansPDaa";
  font-style: normal;
  font-weight: normal;
  src: local("PassionSansPDaa"),
    url("./fonts/passionSansPd/PassionSansPDaa-Hairline.ttf") format("truetype");
}

@font-face {
  font-family: "PassionSansPDab HairlineI 2";
  font-style: normal;
  font-weight: normal;
  src: local("PassionSansPDab HairlineI 2"),
    url("./fonts/passionSansPd/PassionSansPDab-HairlineI 2.ttf") format("truetype");
}

@font-face {
  font-family: "PassionSansPDab HairlineIta";
  font-style: normal;
  font-weight: normal;
  src: local("PassionSansPDab HairlineIta"),
    url("./fonts/passionSansPd/PassionSansPDab-HairlineIta.ttf") format("truetype");
}

@font-face {
  font-family: "PassionSansPDac Light";
  font-style: normal;
  font-weight: normal;
  src: local("PassionSansPDac Light"),
    url("./fonts/passionSansPd/PassionSansPDac-Light.ttf") format("truetype");
}

@font-face {
  font-family: "PassionSansPDac Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("PassionSansPDac Light Italic"),
    url("./fonts/passionSansPd/PassionSansPDad-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "PassionSansPDae Book";
  font-style: normal;
  font-weight: normal;
  src: local("PassionSansPDae Book"),
    url("./fonts/passionSansPd/PassionSansPDae-Book.ttf") format("truetype");
}

@font-face {
  font-family: "PassionSansPDaf BookItalic";
  font-style: normal;
  font-weight: normal;
  src: local("PassionSansPDaf BookItalic"),
    url("./fonts/passionSansPd/PassionSansPDaf-BookItalic.ttf") format("truetype");
}

@font-face {
  font-family: "PassionSansPDaf Regular";
  font-style: normal;
  font-weight: normal;
  src: local("PassionSansPDaf Regular"),
    url("./fonts/passionSansPd/PassionSansPDag-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "PassionSansPDah Regular Italic";
  font-style: normal;
  font-weight: normal;
  src: local("PassionSansPDah Regular Italic"),
    url("./fonts/passionSansPd/PassionSansPDah-RegularItal.ttf") format("truetype");
}

@font-face {
  font-family: "PassionSansPDai SemiBold";
  font-style: normal;
  font-weight: normal;
  src: local("PassionSansPDai SemiBold"),
    url("./fonts/passionSansPd/PassionSansPDai-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "PassionSansPDaj SemiBold Ita";
  font-style: normal;
  font-weight: normal;
  src: local("PassionSansPDaj SemiBold Ita"),
    url("./fonts/passionSansPd/PassionSansPDaj-SemiBoldIta.ttf") format("truetype");
}

@font-face {
  font-family: "PassionSansPDak Bold";
  font-style: normal;
  font-weight: normal;
  src: local("PassionSansPDak Bold"),
    url("./fonts/passionSansPd/PassionSansPDak-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "PassionSansPDal Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("PassionSansPDal Bold Italic"),
    url("./fonts/passionSansPd/PassionSansPDal-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "PassionSansPDam Heavy";
  font-style: normal;
  font-weight: normal;
  src: local("PassionSansPDam Heavy"),
    url("./fonts/passionSansPd/PassionSansPDam-Heavy.ttf") format("truetype");
}

@font-face {
  font-family: "PassionSansPDan Heavy Italic";
  font-style: normal;
  font-weight: normal;
  src: local("PassionSansPDan Heavy Italic"),
    url("./fonts/passionSansPd/PassionSansPDan-HeavyItalic.ttf") format("truetype");
}

@font-face {
  font-family: "PassionSansPDao ExtraBold";
  font-style: normal;
  font-weight: normal;
  src: local("PassionSansPDao ExtraBold"),
    url("./fonts/passionSansPd/PassionSansPDao-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "PassionSansPDap ExtraBoldIt";
  font-style: normal;
  font-weight: normal;
  src: local("PassionSansPDap ExtraBoldIt"),
    url("./fonts/passionSansPd/PassionSansPDap-ExtraBoldIt.ttf") format("truetype");
}

@font-face {
  font-family: "PassionSansPDaq Black";
  font-style: normal;
  font-weight: normal;
  src: local("PassionSansPDaq Black"),
    url("./fonts/passionSansPd/PassionSansPDaq-Black.ttf") format("truetype");
}

@font-face {
  font-family: "PassionSansPDar Black Italic";
  font-style: normal;
  font-weight: normal;
  src: local("PassionSansPDar Black Italic"),
    url("./fonts/passionSansPd/PassionSansPDar-BlackItalic.ttf") format("truetype");
}

@font-face {
  font-family: "PassionSansPDba HairlineSma";
  font-style: normal;
  font-weight: normal;
  src: local("PassionSansPDba HairlineSma"),
    url("./fonts/passionSansPd/PassionSansPDba-HairlineSma.ttf") format("truetype");
}

@font-face {
  font-family: "PassionSansPDbc LightSmallC";
  font-style: normal;
  font-weight: normal;
  src: local("PassionSansPDbc LightSmallC"),
    url("./fonts/passionSansPd/PassionSansPDbc-LightSmallC.ttf") format("truetype");
}

@font-face {
  font-family: "PassionSansPDbe BookSmallCa";
  font-style: normal;
  font-weight: normal;
  src: local("PassionSansPDbe BookSmallCa"),
    url("./fonts/passionSansPd/PassionSansPDbe-BookSmallCa.ttf") format("truetype");
}

@font-face {
  font-family: "PassionSansPDbg RegularSmal";
  font-style: normal;
  font-weight: normal;
  src: local("PassionSansPDbg RegularSmal"),
    url("./fonts/passionSansPd/PassionSansPDbg-RegularSmal.ttf") format("truetype");
}

@font-face {
  font-family: "PassionSansPDbi SemiBoldSma";
  font-style: normal;
  font-weight: normal;
  src: local("PassionSansPDbi SemiBoldSma"),
    url("./fonts/passionSansPd/PassionSansPDbi-SemiBoldSma.ttf") format("truetype");
}

@font-face {
  font-family: "PassionSansPDbk BoldSmallCa";
  font-style: normal;
  font-weight: normal;
  src: local("PassionSansPDbk BoldSmallCa"),
    url("./fonts/passionSansPd/PassionSansPDbk-BoldSmallCa.ttf") format("truetype");
}

@font-face {
  font-family: "PassionSansPDca OutlineLigh";
  font-style: normal;
  font-weight: normal;
  src: local("PassionSansPDca OutlineLigh"),
    url("./fonts/passionSansPd/PassionSansPDca-OutlineLigh.ttf") format("truetype");
}

@font-face {
  font-family: "PassionSansPDcb OutlineHeav";
  font-style: normal;
  font-weight: normal;
  src: local("PassionSansPDcb OutlineHeav"),
    url("./fonts/passionSansPd/PassionSansPDcb-OutlineHeav.ttf") format("truetype");
}

@font-face {
  font-family: "PassionSansPDcc Contour";
  font-style: normal;
  font-weight: normal;
  src: local("PassionSansPDcc Contour"),
    url("./fonts/passionSansPd/PassionSansPDcc-Contour.ttf") format("truetype");
}

@font-face {
  font-family: "PassionSansPDcd AccentOuter";
  font-style: normal;
  font-weight: normal;
  src: local("PassionSansPDcd AccentOuter"),
    url("./fonts/passionSansPd/PassionSansPDcd-AccentOuter.ttf") format("truetype");
}

@font-face {
  font-family: "PassionSansPDce AccentCente";
  font-style: normal;
  font-weight: normal;
  src: local("PassionSansPDce AccentCente"),
    url("./fonts/passionSansPd/PassionSansPDce-AccentCente.ttf") format("truetype");
}

@font-face {
  font-family: 'Lato';
  src: local('Lato Regular'), local('Lato-Regular'),
    url('./fonts/Lato/Lato-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato Black';
  src: local('Lato Black'), local('Lato-Black'),
    url('./fonts/Lato/Lato-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato Black Italic';
  src: local('Lato Black Italic'), local('Lato-BlackItalic'),
    url('./fonts/Lato/Lato-BlackItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato Bold';
  src: local('Lato Bold'), local('Lato-Bold'),
    url('./fonts/Lato/Lato-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato Bold Italic';
  src: local('Lato Bold Italic'), local('Lato-BoldItalic'),
    url('./fonts/Lato/Lato-BoldItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato Italic';
  src: local('Lato Italic'), local('Lato-Italic'),
    url('./fonts/Lato/Lato-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato Light';
  src: local('Lato Light'), local('Lato-Light'),
    url('./fonts/Lato/Lato-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato Light Italic';
  src: local('Lato Light Italic'), local('Lato-LightItalic'),
    url('./fonts/Lato/Lato-LightItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato Thin';
  src: local('Lato Thin'), local('Lato-Thin'),
    url('./fonts/Lato/Lato-Thin.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato Thin Italic';
  src: local('Lato Thin Italic'), local('Lato-ThinItalic'),
    url('./fonts/Lato/Lato-ThinItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Alt Bold';
  src: local('Proxima Nova Alt Bold'), local('ProximaNovaAltBold'),
    url('./fonts/proxima/ProximaNovaAltBold.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Alt Light';
  src: local('Proxima Nova Alt Light'), local('ProximaNovaAltLight'),
    url('./fonts/proxima/ProximaNovaAltLight.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Alt Thin';
  src: local('Proxima Nova Alt Thin'), local('ProximaNovaAltThin'),
    url('./fonts/proxima/ProximaNovaAltThin.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Black';
  src: local('Proxima Nova Black'), local('ProximaNovaBlack'),
    url('./fonts/proxima/ProximaNovaBlack.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Bold';
  src: local('Proxima Nova Bold'), local('ProximaNovaBold'),
    url('./fonts/proxima/ProximaNovaBold.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Extrabold';
  src: local('Proxima Nova Extrabold'), local('ProximaNovaExtrabold'),
    url('./fonts/proxima/ProximaNovaExtrabold.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Regular';
  src: local('Proxima Nova Regular'), local('ProximaNova-Regular'),
    url('./fonts/proxima/ProximaNova-Regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Thin';
  src: local('Proxima Nova Thin'), local('ProximaNovaThin'),
    url('./fonts/proxima/ProximaNovaThin.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bodoni';
  font-style: normal;
  font-weight: normal;
  src: local('Bodoni'), url('./fonts/bodoni/bodoni_[allfont.net].woff') format('woff');
}

@font-face {
  font-family: "Brando Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Brando Bold"),
    url("./fonts/brando/Brando Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Brando BoldItalic";
  font-style: normal;
  font-weight: normal;
  src: local("Brando BoldItalic"),
    url("./fonts/brando/Brando BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Brando Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Brando Italic"),
    url("./fonts/brando/Brando Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Brando Normal";
  font-style: normal;
  font-weight: normal;
  src: local("Brando Normal"),
    url("./fonts/brando/Brando Normal.ttf") format("truetype");
}

@font-face {
  font-family: "Minion Black";
  font-style: normal;
  font-weight: normal;
  src: local("Minion Black"),
    url("./fonts/minion/Minion Black.otf") format("truetype");
}

@font-face {
  font-family: "Minion Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Minion Bold Italic"),
    url("./fonts/minion/Minion Bold Italic.otf") format("truetype");
}

@font-face {
  font-family: "Minion Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Minion Bold"),
    url("./fonts/minion/Minion Bold.otf") format("truetype");
}

@font-face {
  font-family: "Minion Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Minion Italic"),
    url("./fonts/minion/Minion Italic.otf") format("truetype");
}

@font-face {
  font-family: "Minion Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Minion Regular"),
    url("./fonts/minion/Minion Regular.ttf") format("truetype");
}


@font-face {
  font-family: "Minion Semi Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Minion Semi Bold Italic"),
    url("./fonts/minion/Minion Semi Bold Italic.ttf") format("truetype");
}


@font-face {
  font-family: "Minion Semibold";
  font-style: normal;
  font-weight: normal;
  src: local("Minion Semibold"),
    url("./fonts/minion/Minion Semibold.otf") format("truetype");
}

@font-face {
  font-family: "DIN BlackAlternate";
  font-style: normal;
  font-weight: normal;
  src: local("DIN BlackAlternate"),
    url("./fonts/din/DIN BlackAlternate.ttf") format("truetype");
}

@font-face {
  font-family: "DIN Bold";
  font-style: normal;
  font-weight: normal;
  src: local("DIN Bold"),
    url("./fonts/din/DIN Bold.otf") format("truetype");
}

@font-face {
  font-family: "DIN BoldAlternate";
  font-style: normal;
  font-weight: normal;
  src: local("DIN BoldAlternate"),
    url("./fonts/din/DIN BoldAlternate.ttf") format("truetype");
}

@font-face {
  font-family: "DIN LightAlternate";
  font-style: normal;
  font-weight: normal;
  src: local("DIN LightAlternate"),
    url("./fonts/din/DIN LightAlternate.ttf") format("truetype");
}

@font-face {
  font-family: "DIN Medium";
  font-style: normal;
  font-weight: normal;
  src: local("DIN Medium"),
    url("./fonts/din/DIN Medium.otf") format("truetype");
}

@font-face {
  font-family: "DIN MediumAlternate";
  font-style: normal;
  font-weight: normal;
  src: local("DIN MediumAlternate"),
    url("./fonts/din/DIN MediumAlternate.ttf") format("truetype");
}

@font-face {
  font-family: "DIN Regular";
  font-style: normal;
  font-weight: normal;
  src: local("DIN Regular"),
    url("./fonts/din/DIN Regular.ttf") format("truetype");
}

@font-face {
  font-family: "DIN RegularAlternate";
  font-style: normal;
  font-weight: normal;
  src: local("DIN RegularAlternate"),
    url("./fonts/din/DIN RegularAlternate.ttf") format("truetype");
}

@font-face {
  font-family: 'Mulish Black';
  font-style: normal;
  font-weight: normal;
  src: local("Mulish Black"), url('./fonts/mulish/Mulish-Black.ttf') format("truetype");
}

@font-face {
  font-family: 'Mulish BlackItalic';
  font-style: normal;
  font-weight: normal;
  src: local("Mulish BlackItalic"), url('./fonts/mulish/Mulish-BlackItalic.ttf') format("truetype");
}

@font-face {
  font-family: 'Mulish Bold';
  font-style: normal;
  font-weight: normal;
  src: local("Mulish Bold"), url('./fonts/mulish/Mulish-Bold.ttf') format("truetype");
}

@font-face {
  font-family: 'Mulish BoldItalic';
  font-style: normal;
  font-weight: normal;
  src: local("Mulish BoldItalic"), url('./fonts/mulish/Mulish-BoldItalic.ttf') format("truetype");
}

@font-face {
  font-family: 'Mulish ExtraBold';
  font-style: normal;
  font-weight: normal;
  src: local("Mulish ExtraBold"), url('./fonts/mulish/Mulish-ExtraBold.ttf') format("truetype");
}

@font-face {
  font-family: 'Mulish ExtraBoldItalic';
  font-style: normal;
  font-weight: normal;
  src: local("Mulish ExtraBoldItalic"), url('./fonts/mulish/Mulish-ExtraBoldItalic.ttf') format("truetype");
}

@font-face {
  font-family: 'Mulish ExtraLight';
  font-style: normal;
  font-weight: normal;
  src: local("Mulish ExtraLight"), url('./fonts/mulish/Mulish-ExtraLight.ttf') format("truetype");
}

@font-face {
  font-family: 'Mulish ExtraLightItalic';
  font-style: normal;
  font-weight: normal;
  src: local("Mulish ExtraLightItalic"), url('./fonts/mulish/Mulish-ExtraLightItalic.ttf') format("truetype");
}

@font-face {
  font-family: 'Mulish Italic';
  font-style: normal;
  font-weight: normal;
  src: local("Mulish Italic"), url('./fonts/mulish/Mulish-Italic.ttf') format("truetype");
}

@font-face {
  font-family: 'Mulish Light';
  font-style: normal;
  font-weight: normal;
  src: local("Mulish Light"), url('./fonts/mulish/Mulish-Light.ttf') format("truetype");
}

@font-face {
  font-family: 'Mulish LightItalic';
  font-style: normal;
  font-weight: normal;
  src: local("Mulish LightItalic"), url('./fonts/mulish/Mulish-LightItalic.ttf') format("truetype");
}

@font-face {
  font-family: 'Mulish Regular';
  font-style: normal;
  font-weight: normal;
  src: local("Mulish Regular"), url('./fonts/mulish/Mulish-Regular.ttf') format("truetype");
}

@font-face {
  font-family: 'Mulish SemiBold';
  font-style: normal;
  font-weight: normal;
  src: local("Mulish SemiBold"), url('./fonts/mulish/Mulish-SemiBold.ttf') format("truetype");
}

@font-face {
  font-family: 'Mulish SemiBoldItalic';
  font-style: normal;
  font-weight: normal;
  src: local("Mulish SemiBoldItalic"), url('./fonts/mulish/Mulish-SemiBoldItalic.ttf') format("truetype");
}